// src/pages/ProcessForm.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { CompanyContext } from '../contexts/CompanyContext';
import CompanyTreeView from '../components/CompanyTreeView';
import { deleteProcess, getProcessById } from '../services/ProcessServices';
import { deleteFile, uploadFile } from '../services/FileServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './Forms.css';
import styles from './Table.module.css';

function ProcessForm() {
  const { id, companyId, parentId } = useParams();
  const [base64File, setBase64File] = useState('');
  const { company, saveProcess, selectNode } = useContext(CompanyContext);
  const [error, setError] = useState(null);
  const [fileExtension, setFileExtension] = useState('');
  const [loading, setLoading] = useState(true);
  const [process, setProcess] = useState({ name: '', description: '', duration: '' });
  const [parent, setParent] = useState(null);
  const [subprocesses, setSubprocesses] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        setLoading(true);
        if (id && id!=='0') {
          const process = await getProcessById(id);
          if (!process.files)
            process.files = [];
          setProcess(process);
          setSubprocesses(process.processes ?? []);
          setParent(process.parentId);
          selectNode('process',process.id);
        } else {
          setProcess({ name: '', description: '', duration: '' });
          setSubprocesses([]);
          setParent(parentId);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching process data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, parentId, selectNode, updateCounter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    process.companyId = companyId;
    process.parentId = parentId ?? '0';
    if (id) process.id = id;
    const r = await saveProcess(process);
    if (r.success === false) {
      setError(r.message);
    } else {
      if (process.parentId === '0')
        navigate('/companies/edit/' + companyId);
      else
        navigate('/process/edit/' + companyId+"/"+process.parentId);      
    }
  };

  const handleDeleteSubprocess = async (id) => {
    setError(null);
    setLoading(true);
    const r = await deleteProcess(id);
    if (r.success === false) {
      setError(r.message);
      setLoading(false);
    } else {
      setUpdateCounter(updateCounter + 1);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64File(reader.result);
        setFileExtension(file.name.split('.').pop());
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = async (index) => {
    await deleteFile('process', process.id, index);
    const files = [...process.files];
    files.splice(index, 1);
    setProcess({ ...process, files: files });
    setUpdateCounter(updateCounter + 1);
  }

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const data = {
      id: id,
      fileContent: base64File,
      fileExtension: fileExtension
    }
    await uploadFile('process',data);
    setUpdateCounter(updateCounter + 1);
  };  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <div className="tree-container">
        <CompanyTreeView company={company} />
      </div>
      <div className="form-container">
        <h2>{id && id!=='0' ? 'Edit Process' : 'Add New Process'}</h2>
        {error && (
          <div>
            <div className='error-message'>
              There was an error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={process.name}
              onChange={(e) => setProcess({ ...process, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              value={process.description}
              onChange={(e) => setProcess({ ...process, description: e.target.value })}
              rows="5"
              required
            />
          </div>

          <div className="form-group">
            <label>Duration</label>
            <input
              type="text"
              value={process.duration}
              onChange={(e) => setProcess({ ...process, duration: e.target.value })}
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Save' : 'Add Process'}</button>
          </div>
        </form>
        {process.files && process.files.map((file, index) => (
          <div key={index} className="file-item">
            <Link to="#" onClick={() => window.open(file, '_blank')}>{`File #${index + 1}`}</Link>
            &nbsp;&nbsp;
            <button onClick={() => handleFileDelete(index)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        ))}
        {parent && parent!=='0' && (
        <div className="form-footer">
          <Link to={"/process/edit/" + companyId+"/"+parent} className="back-link">Return to parent</Link>
        </div>
        )}
        {(!parent || parent==='0') && (
        <div className="form-footer">
          <Link to={"/companies/edit/" + companyId} className="back-link">Return to company</Link>
        </div>
        )}
      </div>

      {id && id!=='0' && (
      <div className="children-container">
        <h2>Subprocesses</h2>
        <button onClick={() => navigate('/process/new/' + companyId + "/" + (id ?? '0'))}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subprocesses.map((subprocess) => (
              <tr key={subprocess.id}>
                <td>{subprocess.name}</td>
                <td>
                  <button onClick={() => navigate(`/process/edit/${companyId}/${subprocess.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteSubprocess(subprocess.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="upload-container">
          <h2>Load file</h2>
          <form onSubmit={handleFileUpload}>
            <div className="form-group">
              <input
                type="file"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn-submit">Upload</button>
            </div>
          </form>
        </div>

      </div>
      )}
    </div>
  );
}

export default ProcessForm;
