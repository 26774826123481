import React, { createContext, useEffect, useState } from 'react';
import { createCompany, getCompanyById, updateCompany } from '../services/CompanyServices';
import { createProcess, updateProcess } from '../services/ProcessServices';

export const CompanyContext = createContext();

export const CompanyProvider = ({ id, children }) => {
  const [addedNode, setAddedNode] = useState(null);
  const [company, setCompany] = useState({ name: '', country: '', slug: '', processes: [], exams: [] });
  const [loading, setLoading] = useState(true);
  const [selectedNode, setSelectedNode] = useState(null);

  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const companyData = await getCompanyById(id);
        setCompany(companyData);
      } catch (error) {
        console.error('Error fetching company:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchCompany();
  }, [id]);

  const saveCompany = async (company) => {
    try {
      setLoading(true);
      if (company.id) {
        await updateCompany(company.id, company);
      } else {
        await createCompany(company);
      }
      return {
        success: true
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting company:', error);
      return {
        success: false,
        message: error.message
      }
    } finally {
      setLoading(false);
    }
  }

  const saveProcess = async (process) => {
    try {
      setLoading(true);
      if (process.id) {
        await updateProcess(process.id, process);
      } else {
        const newProcess = await createProcess(process);
        setAddedNode({ type: 'process', id: newProcess.id, parentId: newProcess.parentId, name: newProcess.name});
      }
      return {
        success: true
      }
    } catch (error) {
      setLoading(false);
      console.error('Error submitting process:', error);
      return {
        success: false,
        message: error.message
      }
    } finally {
      setLoading(false);
    }
  }

  const selectNode = (type,id) => {
    let newId = id;
    if (type === 'process')
      newId = 'pr-'+id;
    else if (type === 'exam')
      newId = 'ex-'+id;
    console.log('selectNode',newId);
    setSelectedNode(newId);
  }

  return (
    <CompanyContext.Provider value={{ 
      addedNode,
      company,
      loading,
      saveCompany,
      saveProcess,
      selectNode,
      selectedNode,
      setCompany
    }}>
      {children}
    </CompanyContext.Provider>
  );
};
