import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../contexts/CompanyContext';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { useNavigate } from 'react-router-dom';

const CompanyTreeView = () => {
    const { addedNode, company, selectedNode } = useContext(CompanyContext);
    const [ data, setData ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const navigate = useNavigate();

    const getSubexams = useCallback((exam) => {
        let subExams = [];
        if (exam.exams) {
            exam.exams.forEach(subexam => {
                subExams.push({ id: "ex-"+subexam.id, name: subexam.name, type: 'exam', subChild: getSubexams(subexam), expanded: true });
            });    
        }
        return subExams;
    }, []);

    const getSubprocesses = useCallback((process) => {
        let subProcesses = [];
        if (process.processes) {
            process.processes.forEach(subprocess => {
                subProcesses.push({ id: "pr-"+subprocess.id, name: subprocess.name, type: 'process', subChild: getSubprocesses(subprocess), expanded: true });
            });
        }
        return subProcesses;
    }, []);

    useEffect(() => {
        let childProcesses = [];
        let childExams = [];
        company.processes.forEach(process => {
            childProcesses.push({ id: "pr-"+process.id, name: process.name, subChild: getSubprocesses(process), expanded: true });
        });
        company.exams.forEach(exam => {
            childExams.push({ id: "ex-"+exam.id, name: exam.name, subChild: getSubexams(exam), expanded: true });
        });
        let treeData = [{
            id: company.id,
            name: company.name,
            subChild: [
                { id: company.id+"-p", name: 'Processes', subChild: childProcesses, expanded: true },
                { id: company.id+"-e", name: 'Exams', subChild: childExams, expanded: true },
            ],
            expanded: true
        }];
        const fields = { dataSource: treeData, id: 'id', text: 'name', child: 'subChild' };
        setData(fields);
        setLoading(false);
    }, [company, getSubexams, getSubprocesses]);

    useEffect(() => {
        const newNode = (nodes) => {
            let rootId = addedNode.type === 'process' ? "pr-"+company.id : "ex-"+company.id;
            for (let node of nodes) {
                if (node.id === addedNode.parentId || (node.id === rootId && addedNode.parentId === '0')) {
                    let newId = addedNode.type === 'process' ? "pr-"+addedNode.id : "ex-"+addedNode.id;
                    node.subChild.push({ id: newId, name: addedNode.name, subChild: [] });
                }
                if (node.subChild.length>0) {
                    newNode(node.subChild);
                }
            }
        };

        if (data.dataSource) {
            let treeData = [...data.dataSource];
            newNode(treeData);
            setData({ ...data, dataSource: treeData });
        }
    }, [addedNode]);

    useEffect(() => {
        const markSelectedNode = (nodes) => {
            for (let node of nodes) {
                if (node.id === selectedNode) {
                    node.isSelected = true;
                    return true;
                }
                if (node.subChild && markSelectedNode(node.subChild)) {
                    return true;
                }
            }
            return false;
        };

        if (data.dataSource) {
            let treeData = [...data.dataSource];
            markSelectedNode(treeData);
            console.log(treeData);
            setData({ ...data, dataSource: treeData });
        }
    }, [selectedNode]);

    const getType = (id) => {
        if (id.startsWith('pr-')) {
            return 'process';
        } else if (id.startsWith('ex-')) {
            return 'exam';
        } else {
            return 'company';
        }
    }

    const onNodeSelected = (args) => {
        const selectedNode = args.nodeData;
        const type = getType(selectedNode.id);
        if (type!== 'company') {
            const companyId = company.id; 
            const childId = selectedNode.id.replace("pr-", "").replace("ex-", "");
            navigate(`/${type}/edit/${companyId}/${childId}`);
        }
        selectedNode.isSelected = true;
    };    

    if (loading) {
        return <h1>Loading...</h1>
    }

    return (
        <div className="treeview">
            <h2>Treeview</h2>
            <TreeViewComponent fields={data} style={{ marginRight: '10px' }} nodeSelected={onNodeSelected} />
        </div>
    );
};

export default CompanyTreeView;