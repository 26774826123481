// src/pages/CompanyForm.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { CompanyContext } from '../contexts/CompanyContext';
import CompanyTreeView from '../components/CompanyTreeView';
import { deleteExam } from '../services/ExamServices';
import { deleteProcess } from '../services/ProcessServices';
import { getCountries } from '../services/CoreServices';

import './Forms.css';
import styles from './Table.module.css';

function CompanyForm() {
  const { id } = useParams();
  const { company, loading, saveCompany, setCompany } = useContext(CompanyContext);
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState(null);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setError(null);
    const fetchCompany = async () => {
      try {
        const countries = await getCountries(); // Usar el servicio para obtener países
        setCountries(countries);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching company:', error);
      }
    }
    
    fetchCompany();
  }, [company,updateCounter]);

  const handleNameChange = (e) => {
    const newName = e.target.value;
    const newSlug = newName.toLowerCase().replace(/\s+/g, '-'); // Generar el slug a partir del nombre
    setCompany({ ...company, name: newName, slug: newSlug });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    const r = await saveCompany(company);
    if (r.success === false) {
      setError(r.message);
    }
  };

  const handleDeleteExam = async (id) => {
    setError(null);
    const r = await deleteExam(id);
    if (r.success === false) {
      setError(r.message);
    } else {
      setUpdateCounter(updateCounter + 1);
    }
  };

  const handleDeleteProcess = async (id) => {
    setError(null);
    const r = await deleteProcess(id);
    if (r.success === false) {
      setError(r.message);
    } else {
      setUpdateCounter(updateCounter + 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      {id && (
        <div className="tree-container">
          <CompanyTreeView company={company} />
        </div>
      )}
      <div className="form-container">
        <h2>{id ? 'Edit Company' : 'New Company'}</h2>
        {error && (
          <div>
            <div className='error-message'>
              There was an error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit} className="company-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={company.name}
              onChange={handleNameChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="country">Country</label>
            <select
              id="country"
              value={company.country}
              onChange={(e) => setCompany({ ...company, country: e.target.value })}
              required
            >
              <option value="">Select a country</option>
              {countries.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="slug">Slug (auto-generado)</label>
            <input
              type="text"
              id="slug"
              value={company.slug}
              readOnly
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Save' : 'Add company'}</button>
          </div>
        </form>
        <div className="form-footer">
          <Link to="/companies" className="back-link">Return to companies</Link>
        </div>
      </div>

      {id && (
        <div className="children-container">
          <h2>Processes</h2>
          <button onClick={() => navigate('/process/new/' + id + "/0")}>
            <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
          </button>
          <br />
          <br />

          <table className={styles.table}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {company.processes.map((process) => (
                <tr key={process.id}>
                  <td>{process.name}</td>
                  <td>
                    <button onClick={() => navigate(`/process/edit/${id}/${process.id}`)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button onClick={() => handleDeleteProcess(process.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br/>
          <br/>
          <h2>Exams</h2>
          <button onClick={() => navigate('/exam/new/' + id + "/0")}>
            <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
          </button>
          <br />
          <br />

          <table className={styles.table}>
            <thead>
              <tr>
                <th style={{ width: '60%' }}>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {company.exams.map((exam) => (
                <tr key={exam.id}>
                  <td>{exam.name}</td>
                  <td>
                    <button onClick={() => navigate(`/exam/edit/${id}/${exam.id}`)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button onClick={() => handleDeleteExam(exam.id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default CompanyForm;
