// src/services/axiosConfig.js
import axios from 'axios';
import { auth } from '../firebaseConfig';

const axiosInstance = axios.create();

// Interceptor de solicitud para agregar el token a todas las solicitudes
axiosInstance.interceptors.request.use(
  config => {
    const idToken = localStorage.getItem('radiopilot-app-idtoken');
    if (idToken) {
      const currentTime = Math.floor(Date.now() / 1000);
      const tokenExpirationTime = JSON.parse(atob(idToken.split('.')[1])).exp;

      if (tokenExpirationTime < currentTime) {
        // Token is expired, renew it
        return auth.currentUser.getIdToken(true).then(newIdToken => {
          localStorage.setItem('radiopilot-app-idtoken', newIdToken);
          config.headers.Authorization = `Bearer ${newIdToken}`;
          return config;
        }).catch(error => {
          return Promise.reject(error);
        });
      } else {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    }
    return config;
  },
  error => {
    // Aquí puedes manejar los errores sin loguearlos automáticamente
    if (error.response) {
      // Error con respuesta del servidor (status 4xx, 5xx)
      // Puedes optar por no hacer nada o manejarlo globalmente
      return Promise.reject(error);
    } else if (error.request) {
      // Error relacionado con la red, evita que Axios lo loguee
      return Promise.reject(error);
    } else {
      // Error en la configuración de la solicitud o Axios
      return Promise.reject(error);
    }
  }
);


export default axiosInstance;
