// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './Login.css';  // Importar estilos específicos del formulario de login

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();
      localStorage.setItem('radiopilot-app-idtoken', idToken);
      navigate('/projects');
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');  
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src="/radiopilot.png" alt="Logo" className="logo" />  {/* Logo en la parte superior */}
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
            />
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
        <button className="forgot-password-link" onClick={handleForgotPassword}>
          Forgot your password?
        </button>
      </div>
    </div>
  );
}

export default Login;
