// src/services/projectService.js
import axiosInstance from "./axiosConfig";

const API_URL = process.env.REACT_APP_API_URL;

export const deleteFile = async (objectType,id,index) => {
  try {
    const url = `${API_URL}/${objectType}/delete-file/${id}/${index}`;
    const response = await axiosInstance.delete(url);
    return response.data;
  }
  catch (error) {
    console.error("Error deleting file:", error);
    throw error;
  }
}

// Crear un nuevo bloque
export const uploadFile = async (objectType,data) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/${objectType}/upload`, data);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
