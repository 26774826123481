// src/pages/ExamForm.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { createExam, deleteExam, getExamById, updateExam } from '../services/ExamServices';
import { deleteFile, uploadFile } from '../services/FileServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './Forms.css';
import styles from './Table.module.css';

function ExamForm() {
  const { id, parentId, companyId } = useParams();
  const [base64File, setBase64File] = useState('');
  const [error, setError] = useState(null);
  const [fileExtension, setFileExtension] = useState('');
  const [loading, setLoading] = useState(true);
  const [exam, setExam] = useState({ name: '', description: '', length: '', preparation: '', scope: '', remarks: '' });
  const [parent, setParent] = useState(null);
  const [subexams, setSubexams] = useState([]);
  const [updateCounter, setUpdateCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError(null);
        setLoading(true);

        if (id && id!=='0') {
          const exam = await getExamById(id);
          if (!exam.files)
            exam.files = [];
          setExam(exam);
          setSubexams(exam.exams);
          setParent(exam.parentId);
        } else {
          setExam({ name: '', description: '', length: '', preparation: '', scope: '', remarks: '' });
          setSubexams([]);
          setParent(parentId);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching exam data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, parentId, updateCounter]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      exam.companyId = companyId;
      exam.parentId = parentId ?? '0';
      if (id) {
        await updateExam(id, exam);
      } else {
        await createExam(exam);
      }
      if (exam.parentId==='0')
        navigate('/companies/edit/' + companyId);
      else
        navigate('/exam/edit/' + companyId+"/"+exam.parentId);
    } catch (error) {
      console.error('Error submitting exam:', error);
    }
  };

  const handleDeleteSubexam = async (id) => {
    setError(null);
    setLoading(true);
    const r = await deleteExam(id);
    if (r.success === false) {
      setError(r.message);
      setLoading(false);
    } else {
      setUpdateCounter(updateCounter + 1);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64File(reader.result);
        setFileExtension(file.name.split('.').pop());
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileDelete = async (index) => {
    await deleteFile('exam', exam.id, index);
    const files = [...exam.files];
    files.splice(index, 1);
    setExam({ ...exam, files: files });
    setUpdateCounter(updateCounter + 1);
  }

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const data = {
      id: id,
      fileContent: base64File,
      fileExtension: fileExtension
    }
    await uploadFile('exam',data);
    setUpdateCounter(updateCounter + 1);
  };  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container">
      <div className="form-container">
        <h2>{id ? 'Edit Exam' : 'Add New Exam'}</h2>
        {error && (
          <div>
            <div className='error-message'>
              There was an error: {error}
            </div>
            <br />
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={exam.name}
              onChange={(e) => setExam({ ...exam, name: e.target.value })}
              required
            />
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              value={exam.description}
              onChange={(e) => setExam({ ...exam, description: e.target.value })}
              rows = "5"
              required
            />
          </div>

          <div className="form-group">
            <label>Length</label>
            <input
              type="text"
              value={exam.length}
              onChange={(e) => setExam({ ...exam, length: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label>Preparation</label>
            <textarea
              value={exam.preparation}
              onChange={(e) => setExam({ ...exam, preparation: e.target.value })}
              rows = "5"
            />
          </div>

          <div className="form-group">
            <label>Scope</label>
            <textarea
              value={exam.scope}
              onChange={(e) => setExam({ ...exam, scope: e.target.value })}
              rows = "5"
            />
          </div>

          <div className="form-group">
            <label>Remarks</label>
            <textarea
              value={exam.remarks}
              onChange={(e) => setExam({ ...exam, remarks: e.target.value })}
              rows = "5"
            />
          </div>

          <div className="form-group">
            <button type="submit" className="btn-submit">{id ? 'Save' : 'Add Exam'}</button>
          </div>
        </form>
        {exam.files && exam.files.map((file, index) => (
          <div key={index} className="file-item">
            <Link to="#" onClick={() => window.open(file, '_blank')}>{`File #${index + 1}`}</Link>
            &nbsp;&nbsp;
            <button onClick={() => handleFileDelete(index)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        ))}
        {parent && parent!=='0' && (
        <div className="form-footer">
          <Link to={"/exam/edit/" + companyId+"/"+parent} className="back-link">Return to parent</Link>
        </div>
        )}
        {(!parent || parent==='0') && (
        <div className="form-footer">
          <Link to={"/companies/edit/" + companyId} className="back-link">Return to company</Link>
        </div>
        )}

      </div>

      {id && id!=='0' && (
      <div className="children-container">
        <h2>Subexams</h2>
        <button onClick={() => navigate('/exam/new/' + companyId + "/" + (id ?? '0'))}>
          <FontAwesomeIcon icon={faPlus} /> {/* Ícono de "+" */}
        </button>
        <br />
        <br />

        <table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subexams.map((subexam) => (
              <tr key={subexam.id}>
                <td>{subexam.name}</td>
                <td>
                  <button onClick={() => navigate(`/exam/edit/${companyId}/${subexam.id}`)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </button>
                  <button onClick={() => handleDeleteSubexam(subexam.id)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="upload-container">
          <h2>Load file</h2>
          <form onSubmit={handleFileUpload}>
            <div className="form-group">
              <input
                type="file"
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn-submit">Upload</button>
            </div>
          </form>
        </div>

      </div>
      )}
    </div>
  );
}

export default ExamForm;
