// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import './ForgotPassword.css';  // Importar estilos específicos del formulario de login
import { sendPasswordResetEmail } from 'firebase/auth';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth,email);
      alert('Se ha enviado un correo para restablecer la contraseña.');
      navigate('/login');
    } catch (error) {
      console.error("Error al enviar el correo de recuperación:", error);
      alert('Error al enviar el correo de recuperación. Por favor, inténtelo de nuevo.');
    }
  };

  const handleLogin = () => {
    navigate('/login');  
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <img src="/radiopilot.png" alt="Logo" className="logo" />  {/* Logo en la parte superior */}
        <h2>Recuperar contraseña</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              required
            />
          </div>
          <button type="submit" className="forgot-password-button">Enviar</button>
        </form>
        <button className="forgot-password-link" onClick={handleLogin}>
          Regresar al inicio de sesión
        </button>
      </div>
    </div>
  );
}

export default ForgotPassword;
