// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Companies from './pages/Companies';
import CompanyForm from './pages/CompanyForm';
import { CompanyProvider } from './contexts/CompanyContext';
import ExamForm from './pages/ExamForm';
import ForgotPassword from './pages/ForgotPassword'; 
import Login from './pages/Login';
import ProcessForm from './pages/ProcessForm';
import Sidebar from './components/Sidebar';
import { useParams } from 'react-router-dom';

import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);  // Estado de carga inicial

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user ? user : null);
      setLoading(false);  // Terminamos la carga cuando Firebase responde
    });
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="loading-screen">Loading...</div>;  // Mostrar pantalla de carga
  }

  return (
    <Router>
      <div className={`App ${user ? 'app-with-sidebar' : ''}`}>
        {user && <Sidebar />}
        <div className="main-content">
          <Routes>
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {!user ? (
              <>
                <Route path="*" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/exam/new/:companyId/:parentId" element={<ExamForm />} />
                <Route path="/exam/edit/:companyId/:id" element={<ExamForm />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/new" element={<CompanyFormWrapper />} />
                <Route path="/companies/edit/:id" element={<CompanyFormWrapper />} />
                <Route path="/process/new/:companyId/:parentId" element={<ProcessFormWrapper />} />
                <Route path="/process/edit/:companyId/:id" element={<ProcessFormWrapper />} />
                <Route path="*" element={<Navigate to="/companies" />} />
              </>
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

function CompanyFormWrapper() {
  const { id } = useParams();
  return (
    <CompanyProvider id={id}>
      <CompanyForm id={id} />
    </CompanyProvider>
  );
}

function ProcessFormWrapper() {
  const { id, companyId, parentId } = useParams();
  return (
    <CompanyProvider id={companyId}>
      <ProcessForm id={id} companyId={companyId} parentId={parentId} />
    </CompanyProvider>
  );
}

export default App;
