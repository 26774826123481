const countries = [
  { name: "Argentina", code: "AR" },
  { name: "Bolivia", code: "BO" },
  { name: "Brazil", code: "BR" },
  { name: "Canada", code: "CA" },
  { name: "Chile", code: "CL" },
  { name: "Colombia", code: "CO" },
  { name: "Ecuador", code: "EC" },
  { name: "Mexico", code: "MX" },
  { name: "Perú", code: "PE" },
  { name: "United States", code: "US" },
  { name: "Venezuela", code: "VE" },
  // Agrega más países según sea necesario
];

export const getCountries = async () => {
  try {
    return countries;
  } catch (error) {
    console.error("Error fetching company:", error);
    throw error;
  }
};

